import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import metaData from "@data/metaData.json";
import data from "@data/businessServices.json";
import { css } from "@emotion/react";
import HeroSection from "../../components/HeroSection";
import ServiceSection from "../../components/services/ServiceSection";

const BusinessServicesPage = () => {
  return (
    <Layout>
      <SEO {...metaData.businessServices} />

      <HeroSection
        css={hero_section_style}
        description={data.heroSection.description}
        title={data.heroSection.title}
        imgAlt={data.heroSection.imageTitle}
        imgSrc={data.heroSection.image}
      />

      {data.services.map((service, index) => (
        <ServiceSection
          data={service}
          data-direction={index % 2 == 0 ? "" : "row-reverse"}
        />
      ))}
    </Layout>
  );
};

export default BusinessServicesPage;

const hero_section_style = css`
  .hero-section__content {
    width: 60%;
  }
  .hero-section__description {
    max-width: 54ch;
  }
  .hero-section__image {
    width: 30%;
  }
`;
